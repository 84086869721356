import React, { useCallback, useState } from "react";
import { ScheduleData } from "./data";

interface ScheduleTitleProps {
  time: string;
  speakers?: string | string[];
  topic?: string;
}
const ScheduleTitle = ({
  time,
  speakers: _speaker,
  topic,
}: ScheduleTitleProps) => {
  const speakers = Array.isArray(_speaker) ? _speaker : [_speaker];
  return (
    <>
      <span className="bg-gray-300">{time}</span>
      {`: `}
      <br className="block md:hidden" />
      <span className={_speaker ? "" : "hidden"}>
        {speakers.map((speaker, index) => (
          <span key={index} className="font-semibold">
            {index !== 0 && ` & `}
            {speaker}
          </span>
        ))}
        {`: `}
      </span>

      <span>{topic}</span>
    </>
  );
};

interface ScheduleItemProps extends ScheduleTitleProps {
  abstract?: React.ReactNode;
  bio?: React.ReactNode;
  className?: string;
  open?: boolean;
  onClick?: () => void;
}
const ScheduleItem = ({
  time,
  speakers = "",
  topic = "",
  abstract,
  bio,
  className = "",
  open,
  onClick,
}: ScheduleItemProps) => {
  const hasDetail = abstract || bio;

  if (!hasDetail) {
    return (
      <div className="pl-[1.375rem] p-2">
        <ScheduleTitle time={time} speakers={speakers} topic={topic} />
      </div>
    );
  }

  return (
    <details
      open={open}
      className={`flex flex-col p-2 ${
        abstract ? "cursor-pointer" : ""
      } ${className}`}
      onClick={onClick}
    >
      <summary>
        <ScheduleTitle time={time} speakers={speakers} topic={topic} />
      </summary>
      <div className="pl-6">
        <p className="mt-2">
          <span className="font-bold">Abstract: </span>
          {abstract}
        </p>
        <p className="mt-2">
          <span className="font-bold">Bio: </span>
          {bio}
        </p>
      </div>
    </details>
  );
};

const Schedule = () => {
  const [toggleState, setToggleState] = useState<Map<number, boolean>>(
    new Map()
  );
  const [forceOpen, setForceOpen] = useState(false);
  return (
    <>
      <button
        className="my-4 border border-black rounded-md px-2 cursor-pointer text-sm"
        onClick={() => setForceOpen((state) => !state)}
      >
        {forceOpen === true ? "Close detail" : "See all detail"}
      </button>
      {ScheduleData.map((item, index) => (
        <ScheduleItem
          {...item}
          key={index}
          open={forceOpen || toggleState.get(index)}
          onClick={() => {
            setToggleState((state) => state.set(index, !state.get(index)));
          }}
        />
      ))}
    </>
  );
};

export default Schedule;
