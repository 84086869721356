import React, { FC } from "react";
import Helmet from "react-helmet";
import { SocialIcon } from "react-social-icons";
import "./layout.css";
import Schedule from "./schedule";
import { Materials } from "./data";

import Logo from "./icon.png";

const COMMITTEE_LIST: Array<[string, string]> = [
  ["Chen-Mou Cheng", "BTQ AG"],
  ["Kai-Min Chung", "Academia Sinica"],
  ["Jintai Ding", "Tsinghua University"],
  ["Tsuyoshi Takagi", "University of Tokyo"],
  ["Bo-Yin Yang", "Academia Sinica"],
];

const INVITED_SPEAKER: Array<[string, string]> = [
  ["Jimmy Chen", "WiSECURE Technologies"],
  ["Jintai Ding", "Tsinghua University"],
  ["Matthias Kannwischer", "Academia Sinica"],
  ["Eunkyung Kim", "Samsung"],
  ["Po-Chun Kuo", "BTQ AG"],
  ["Ting-Gian Lua", "BTQ AG"],
  ["Adrian Marotzke", "NXP Semiconductors"],
  ["Dustin Moody", "NIST"],
  ["Shiho Moriai", "NICT"],
  ["Peter Schwabe", "MPI-SP"],
  ["Daniel Smith-Tone", "NIST"],
  ["Keita Xagawa", "NTT"],
];

const SubTitle: FC<{ children: React.ReactNode }> = ({ children }) => (
  <h3 className="text-2xl mt-4 mb-2 font-bold">{children}</h3>
);

const Section: FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => <section className={`mb-14 ${className}`}>{children}</section>;

const List = ({ items }: { items: Array<[string, string]> }) => (
  <ul className="list-disc">
    {items.map(([name, org], index) => (
      <li key={index}>
        <b className="text-[#3b757e] text-lg">{name}</b>{" "}
        <span className="text-sm">({org})</span>
      </li>
    ))}
  </ul>
);

const Area: FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <div className={`${className}`}>
    <div className="m-auto w-10/12 md:w-9/12 lg:w-[50rem]">{children}</div>
  </div>
);

const SocialShareText = `
https://events.btq.li/pqc-workshop-2022/
PQC Standardization and Migration Workshop
5 December 2022
Online and Onsite at Hotel Regent, Taipei, Taiwan
`;

const SITE_URL = "https://events.btq.li/pqc-workshop-2022/";

const PqcWorkshop = () => {
  return (
    <>
      <Helmet>
        <title>PQC Standardization & Migration Workshop</title>
        <meta
          name="description"
          content="PQC Standardization & Migration Workshop"
        />
        <meta property="og:url" content={SITE_URL} />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="PQC Standardization & Migration Workshop"
        />
        <meta
          property="og:description"
          content="5 December 2022. Online and Onsite at Hotel Regent, Taipei, Taiwan"
        />

        <link rel="icon" href={Logo} sizes="any" />
      </Helmet>

      <Area className="bg-[#7DA2A9]">
        <Section className="py-5 text-center ">
          <h1 className="py-2 mb-4 sm:mb-8 text-2xl sm:text-3xl md:text-4xl text-[#F7F7F7]">
            PQC Standardization & Migration Workshop
          </h1>
          <div className="text-sm sm:text-base text-[#f7f7f7] py-2">
            <div className="text-xl mb-2">5 December 2022</div>
            <div>Online and Onsite at Hotel Regent Taipei, Taiwan</div>
            <div>Collocated with Asiacrypt 2022</div>
          </div>
        </Section>
      </Area>

      <Area>
        <Section className="">
          <SubTitle>Organizing Committee</SubTitle>
          <List items={COMMITTEE_LIST} />
        </Section>

        <Section>
          <SubTitle>Invited Speaker</SubTitle>
          <List items={INVITED_SPEAKER} />
        </Section>

        <Section>
          <SubTitle>Program</SubTitle>
          <Schedule />
        </Section>

        <Section>
          <SubTitle>Call for Presentations</SubTitle>
          <div>
            In July 2022, NIST officially announced four candidates for PQC
            standardization. This is certainly a landmark milestone after nearly
            six years of hard work by the PQC research community all over the
            world; yet it is also just only the beginning of a long and
            extensive process of migration to post-quantum cyberspace, requiring
            even greater efforts from not only the research community but also
            the cybersecurity industry as a whole. Therefore, the organizing
            committee of this workshop is calling for presentations from both
            academia and industry on topics related to the challenges and
            potential solutions, as well as strategies and guidelines pertaining
            to PQC standardization and migration in the next few years. Please
            submit a title, abstract, and speaker bio for a 15–30 minute talk to{" "}
            <a href="mailto: cheng@btq.li" className="text-blue-600">
              cheng@btq.li
            </a>{" "}
            before the deadline of 15 Nov 2022 if you are interested. Selection
            will be based on the relevance and merits of the submissions, whose
            results will be announced roughly two weeks before the workshop.
          </div>
        </Section>
      </Area>
      <Area>
        {Materials.map(({ title, link }) => (
          <div
            key={link}
            className="mt-2 underline text-sky-500 hover:text-sky-800"
          >
            <a href={link} target="_blank">
              {title}
            </a>
          </div>
        ))}
      </Area>
      <Area>
        <div className="flex justify-end items-center pb-8">
          <span className="text-lg text-[#3b757e]">Share</span>

          <SocialIcon
            network="twitter"
            className="ml-4 cursor-pointer"
            onClick={() => {
              window.open(
                `https://twitter.com/intent/tweet?text=${encodeURI(
                  SocialShareText
                )}`
              );
            }}
          />

          <SocialIcon
            network="linkedin"
            className="ml-4 cursor-pointer"
            onClick={() =>
              window.open(
                `http://www.linkedin.com/shareArticle?mini=false&url=${encodeURI(
                  SITE_URL
                )}&title=${encodeURI(
                  "PQC Standardization and Migration Workshop"
                )}&summary=${encodeURI(SocialShareText)}&source=${encodeURI(
                  SITE_URL
                )}
              `
              )
            }
          />

          <SocialIcon
            network="facebook"
            className="ml-4 cursor-pointer"
            onClick={() => {
              window.open(`
                https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                  SITE_URL
                )}`);
            }}
          />
        </div>
      </Area>
    </>
  );
};

export default PqcWorkshop;
